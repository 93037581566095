.blog {
  padding: 0;
  margin: 0;
  background-color: #e8e8e8;

  .blog-border{
    background-color: #2464af;
    height: 50px;
    width: 100%;
  }
  .jumbotron {
    display: flex;
    justify-content: start;
    align-items: center;
    background-image: url("../images/analytics/Analytics_Header.png");
    background-size: cover;
    height: 325px;
    background-position: top center;

    @media screen and (max-width: 992px) {
      h1 {
        font-size: 3rem !important;
      }
      h4 {
        font-size: 2rem !important;
      }
    }
  }

  &__body {
    background: #e8e8e8 !important;

    h5, h6, a {
      color: #062b5a;
    }

    h6 {
      font-style: italic;
    }

    p {
      color: #8e8e8e;
    }

    hr {
      color: #707070;
      width: 100%;
      height: 4px !important;
    }
    .blog-card{
      background-color: #ffffff;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 0px 2px 0px #000000;
      height: 100%;
      display: block;
      transition: all ease-in-out .5s;
      text-decoration: none;
      .img-link{
      }
      .blog-card-content{
        padding: 10px;
        p{
          margin-bottom: 0px;
          margin-top: 15px;
          font-weight: bold;
        }
      }
    }
    .blog-card:hover{
      transform: translateY(-10px);
    }
  }

  .blog_pagination_btn{
    background-color: #2464af;
    color: #ffffff;
    padding: 7px 18px;
    text-decoration: none!important;
    margin: 0 5px;
  }
  .blog_pagination_btn:hover {
    background-color: #4088d9 !important;
  }
}



//BLOG ENTRY STYLES
.blog-entry-content {
  p {
    color: #000000 !important;
  }
}