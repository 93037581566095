.analytics {
  padding: 0px;
  margin: 0px;
  background-color: #e8e8e8;

  .jumbotron {
    display: flex;
    justify-content: start;
    align-items: center;
    background-image: url("../images/analytics/Analytics_Header.png");
    background-size: cover;
    height: 325px;
    background-position: top center;
    @media screen and (max-width: 992px) {
      h1 {
        font-size: 3rem !important;
      }
      h4 {
        font-size: 2rem !important;
      }
    }
  }

  &__body {
    padding: 0px !important;
    background: #e8e8e8 !important;

    &--container1 {
      p {
        color: #062b5a;
        font-size: 23px;
        line-height: 2;
        font-weight: bold;
      }
    }

    &--container2 {
      //   padding: 50px;
      background-image: url("../images/PDA_Home_Header.png");
      background-size: cover;

      p {
        color: #fff;
        font-weight: bold;
        font-size: 20px;
      }

      .benefits {
        h4 {
          font-size: 85px;

          span {
            font-size: 140px;
          }
        }

        @media screen and (max-width: 1200px) {
          h4 {
            font-size: 65px;

            span {
              font-size: 115px;
            }
          }
        }
        @media screen and (max-width: 992px) {
          h4 {
            font-size: 45px;

            span {
              font-size: 80px;
            }
          }
        }
        @media screen and (max-width: 992px) {
          h4 {
            font-size: 35px;

            span {
              font-size: 62px;
            }
          }
        }

        .computer {
          position: relative;

          img {
            position: absolute;
            margin-top: -110px;
          }

          @media screen and (max-width: 1400px) {
            img {
              margin-top: -62px;
            }
          }
          @media screen and (max-width: 992px) {
            img {
              margin-top: 0px;
              position: relative;
            }
          }
        }
      }

      .ramp {
        background-color: #2464af;
        margin-top: 15px;

        .rampBullet {
          display: flex;
          flex-direction: row;
          margin: 25px;
          align-items: center;

          .benefit-index {
            margin-right: 20px;
            padding: 10px 25px;
            font-size: 36px;
            font-weight: bold;
            background-color: #062b5a;
            color: #ffffff;
            border: solid 2px #ffffff;
          }

        }
      }
    }

    .analyticsBottomContent {
      font-size: 2rem;
      font-weight: 500;
    }
  }
}


//Cell Phones
@include media-breakpoint-down(sm) {
  .analytics {
    .jumbotron {
      h1, h4 {
        text-align: center;
      }

      h1 {
        font-size: 1.5em;
      }

      h4 {
        font-size: 1.2em;
      }

      hr {
        margin: 1rem auto;
      }
    }

    &__body {
      &--container1 {
        p {
          font-size: 1rem;
          padding: 0 1rem;
        }
      }

      &--container2 {
        p {
          font-size: 16px;
        }
      }
    }

    .ramp {
      .rampBullet {
        margin: 10px 0;
        align-items: flex-start;
        .benefit-index {
          margin-right: 10px;
          margin-top: 10px;
          padding: 10px 25px;
          font-size: 26px;
        }

        img {
          width: 70%;
          margin-top: 10px;
        }

        p {
          padding: 0 1rem;
        }
      }

    }

    .analyticsBottomContent {
      p {
        font-size: 1.2rem;
        margin-bottom: 0;
      }
    }
  }
}

