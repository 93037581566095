.jumbotron {
  display: flex;
  justify-content: start;
  align-items: center;
  background-image: url("../images/PDA_Home_Header.png");
  background-size: cover;
  padding: 7% 0 10%;
  background-position: top center;
}

.homePage {
  background-image: url("../images/homeBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__card {
    //margin-top: -138px;
    //margin-bottom: 200px;
    transform: translate(0%, -25%);

    &--img {
      position: relative;
      margin: 0;
      text-align: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 250px;
      width: 250px;
      // height: 100%;
      // width: 100%;

      div {
        display: none !important;
        position: absolute;
        background-color: #2464af;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        p {
          color: #fff;
          font-size: 80%;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      &--img {
        height: 200px;
        width: 200px;
      }
    }

    &--img:hover div {
      display: flex !important;
      justify-content: center;
      align-items: center;
      z-index: 2000;

    }

    &--img:nth-child(1):before,
    &--img:nth-child(2):before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 10%;
      top: 10%;
      height: 80%;
      border-right: 4px solid #2464af;
      padding: 10px;
    }
  }

  .companyHistory-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //justify-content: flex-start;
    //align-items: center;
    .header-img-div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .companyHistory {
    .company-mission {
      font-size: 1.3rem;

      iframe {
        width: 100%;
        min-height: 250px;
      }
    }

    h3 {
      font-size: 1.5rem;
      white-space: nowrap;
    }

    div {
      padding: 20px;

      img {
        height: 12px;
      }
    }
  }


  .guidingPrinciples {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      font-size: 2rem;
      white-space: nowrap;
      color: #fff !important;
    }

    div {
      padding: 0 20px;
      width: 100%;

      img {
        height: 12px;
        width: 100%;
      }
    }
  }

  &__slider {
    background: #2464af;
    height: 300px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .carousel-indicators {
      margin-bottom: -3rem !important;

      button {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
      }
    }

    .carousel-index {
      display: inline-block;
      background-color: #062b5a;
      border: solid 2px #ffffff;
      padding: 10px 20px;
    }
  }

  &__joinPDA {
    background: #fff;
  }
}


.modal-content {
  background-image: url("./../images/PopUp.jpg");
  background-size: cover;
  padding: 3em 1em;

  p {
    text-align: center;
    font-size: 1.5em;
    color: #ffffff;
  }
}


//Tablet
@include media-breakpoint-down(md) {
  .homePage {
    &__card {
      transform: translate(0%, 0%);
      padding: 5rem 0;

      &--img {
        position: relative;
        margin-top: 10px;
      }

      &--img:nth-child(1):before,
      &--img:nth-child(2):before {
        content: "";
        position: absolute;
        border: none;
      }
    }

    &__slider {
      background: #2464af;
    }
  }
}

//Cell Phones
@include media-breakpoint-down(sm) {
  .jumbotron {
    h1, h4 {
      text-align: center;
    }

    h1 {
      font-size: 1.5em;
    }

    h4 {
      font-size: 1.2em;
    }

    hr {
      margin: 1rem auto;
    }
  }

  .homePage__card {
    padding-top: 2rem;

    .homePage__card--img {
      height: 250px;
      width: 250px;
      box-shadow: 0px 0px 15px 0px #A3A3A3;
    }
  }

  .companyHistory-header {
    justify-content: center;

    h3 {
      font-size: 1.4rem;
    }

    .header-img-div {
      width: 30%;
    }
  }

  .company-mission {
    padding-bottom: 0 !important;

    p {
      font-size: 1rem;
    }

    figure {
      margin-bottom: 0 !important;
    }
  }

  .homePage__slider {
    .guidingPrinciples {
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 1.8rem;
        margin-bottom: 30px;
      }

      img {
        display: none;
      }
    }

    // CAROUSEL
    .carousel {
      .carousel-item {
        h4{
          font-size: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 80%;
          margin: 0 auto;
          .carousel-index{
            width: 50px;
            margin-bottom: 20px;
            padding: 10px;
          }
        }
      }
    }
  }

  #people{
    flex-direction: column-reverse;
    align-items: center !important;
  }
}
