.nav,
.nav-mobile {
  background-color: $gray;
}

//DESKTOP
.nav {
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    //justify-content: space-between !important;
    justify-content: center;

    .img {
      width: 75%;
    }
  }

  &__links {
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    a {
      text-decoration: none;
      font-size: 1.5rem;
      padding: 0 .7rem;
      border-right: solid 3px #000000;
      color: $blue;
    }

    a:last-of-type {
      border: none;
    }
    a:hover{
      color: $blue-alt;
    }
  }
}

//MOBILE
.nav-mobile {
  display: none;

  .navbar-collapse {
    ul {
      list-style: none;

      li {
        a {
          text-decoration: none;
          color: $blue;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 1.2rem;
          display: block;
          width: 100%;
        }

        a:hover {
          background:$gray-alt;
          //color: $blue-alt;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .nav {
    display: none;
  }
  .nav-mobile {
    display: block;
    .navbar-brand{
      display: flex;
      align-items: center;
    }
  }
}
