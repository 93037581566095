
body{
    background:$gray;
}
h1, h4, h5{
    color: #fff;
    line-height: 1;
}

h1{
    font-size: 5rem;
}

p{
    color: #062B5A;
}
@media screen and (max-width: 500px){
    h1{
        font-size: 4rem;
    }
}
@media screen and (max-width: 500px){
    h1{
        font-size: 3rem;
    }
}
h2, h3{
    color: #2464AF;
}

h4{
    font-size: 47px;
}

hr{
    color: #fff;
    width: 120px;
    height: 8px !important;
    opacity: 1;
}
