.people {
  padding: 0;
  margin: 0;

  .jumbotron {
    display: flex;
    justify-content: start;
    align-items: center;
    background-image: url("../images/people/People_Header.png");
    background-size: cover;
    height: 325px;
    background-position: top center;
    @media screen and (max-width: 992px) {
      h1 {
        font-size: 3rem !important;
      }
      h4 {
        font-size: 2rem !important;
      }
    }
  }

  &__body {
    background: #e8e8e8 !important;

    &--container1 {
      p {
        //color: #ffffff;
        font-size: 23px;
        //line-height: 1;
        font-weight: bold;
      }
    }

    &--container2 {
      padding: 50px;
      background-image: url("../images/PDA_Home_Header.png");
      background-size: cover;

      #retention {
        background: #2464af;
      }

      #resign,
      #exit,
      #orientation,
      #employee {
        background: #fff;
      }
    }
  }
}

@keyframes accordion {
  from {
    left: 40%;
  }
  to {
    left: 60%;
    height: 90%;
  }
}

.accContainerDesktop {
  position: relative;

  .accordion {
    margin: 0 20px 20px 0;
    padding: 5px;
    text-align: left;
    position: relative;
    color: #2464AF;
    display: block;
    width: 50%;
    background-color: #fff;
    cursor: pointer;
    transition: 1s;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      h2 {
        margin-left: 20px;
      }
    }
  }


  .selected {
    background-color: #2464AF;

    h2 {
      color: #fff !important;
    }
  }

  .panel {
    position: absolute !important;
    top: 0;
    height: 50px;
    width: 40%;
    padding: 0 18px;
    display: none;
    background-color: #1b5294;
    opacity: 85%;
    overflow: hidden;
    animation-name: accordion;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    justify-content: center;
    align-items: center;

    p {
      color: #fff !important;
      font-size: 22px;
    }
  }
}

.accContainerMobile {
  .accordion-item {
    opacity: 85%;

    .accordion-header {
      background-color: #fff;

      button {
        color: #2464AF;
        font-size: calc(1.325rem + 0.9vw);
      }
    }

    .accordion-body {
      background-color: #2464AF;
      color: #fff !important;

      p {
        color: #ffffff;
      }
    }

    .accordion-button:not(.collapsed) {
    }
  }
}


//Cell Phones
@include media-breakpoint-down(sm) {
  .people {
    .jumbotron {
      h1, h4 {
        text-align: center;
      }

      h1 {
        font-size: 1.5em;
      }

      h4 {
        font-size: 1.2em;
      }

      hr {
        margin: 1rem auto;
      }
    }

    .people__body{
      padding: 0;
      p{
        font-size: 1rem;
      }
    }
  }
}