@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import "variables";
@import "partials/index";
@import "home";
@import "people";
@import "data";
@import "analytics";
@import "blog";
@import "404";
@import "utility";





