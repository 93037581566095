.data {
  padding: 0;
  margin: 0;
  background-color: #e8e8e8;

  .jumbotron {
    display: flex;
    justify-content: start;
    align-items: center;
    background-image: url("../images/data/data_Header.png");
    background-size: cover;
    height: 325px;
    background-position: top center;
    @media screen and (max-width: 992px) {
      h1 {
        font-size: 3rem !important;
      }
      h4 {
        font-size: 2rem !important;
      }
    }

  }

  &__body {
    background: #e8e8e8 !important;
    padding: 0 !important;
    margin: 0 !important;

    &--container1 {
      p {
        color: #062b5a;
        font-size: 23px;
        //line-height: 1;
        font-weight: bold;
      }
    }

    &--container2 {
      padding: 50px;
      background-image: url("../images/PDA_Home_Header.png");
      background-size: cover;

      h4 {
        font-size: 32px;
        margin-bottom: 45px;
      }


      .dataDownload {
        background: #2464af;
        color: #fff;
        text-align: center;
        padding: 0;
        font-size: 26px;
        border: 1px solid #fff;
        margin: 10px;

        a {
          width: 100%;
          height: 100%;
          display: block;
          text-decoration: none;
          color: #ffffff;
        }

        a:hover {
          background: #5293e0;
        }
      }
    }

    &--container3 {
      padding: 50px;

      h5 {
        color: #2464af;
        font-weight: bold;

      }

      .report {
        background: #2464af;
        color: #fff;
        text-align: center;
        padding: 0px;
        font-size: 26px;
        margin: 10px;

        a {
          display: block;
          color: #ffffff;
          width: 100%;
          height: 100%;
          text-decoration: none;
          padding: 10px;
        }

        a:hover {
          background: #538ede;
        }
      }
    }

    &--container4 {
      padding: 50px;
      background: #2464af;

      .hr {
        width: 35%;
      }

      @media screen and (max-width: 1400px) {
        .hr {
          width: 100%;
        }
      }

      .survey {
        background: #062b5a;
        color: #fff;
        text-align: center;
        padding: 0px;
        font-size: 26px;
        border: 1px solid #fff;
        margin: 10px;

        a {
          text-decoration: none;
          color: #ffffff;
          display: block;
          height: 100%;
          width: 100%;
          padding: 10px;
        }

        a:hover {
          background: #3d7ac1;
        }
      }
    }

    //&--container5 {
    #contact-form {
      padding: 50px;
      background-image: url("../images/PDA_Home_Header.png");
      background-size: cover;

      h5 {
        font-size: 40px;
      }

      p {
        color: #fff;
        font-size: 20px;
      }

      input {
        width: 80%;
      }

      button {
        border-radius: 1rem;
      }

    }
  }
}


//Freeform Forms plugin style
.submit-btn {
  background-color: #2464AF;
  border: solid 1px #ffffff !important;
  padding: 5px 30px;
  color: #ffffff;
}

.submit-btn:hover {
  color: #ffffff;
  background-color: #3784e0;
}


//Cell Phones
@include media-breakpoint-down(sm) {
  .data {
    .jumbotron {
      h1, h4 {
        text-align: center;
      }

      h1 {
        font-size: 1.5em;
      }

      h4 {
        font-size: 1.2em;
      }

      hr {
        margin: 1rem auto;
      }
    }
    .data__body {
      p {
        font-size: 1rem;
      }
    }
    .data__body--container2,
    .data__body--container3,
    .data__body--container4 {
      h4{
        margin-bottom: 0 !important;
        text-align: center;
      }
      h5{
        padding: 1rem 0;
        text-align: center;
      }
      img{
        display: none;
      }
      a{
        font-size: 1rem;
      }
    }
  }
}

