footer {
  background-color: #e8e8e8;

  a{
    display: inline-block;
    text-decoration: none;
  }


  .bi-facebook {
    color: #212246;
  }

  .bi-twitter {
    color: #446491;
  }

  .bi-linkedin {
    color: #446491;
  }

  .bi-youtube {
    color: #9e1b20 !important;
  }

  .bi-rss-fill {
    color: #444345;
  }

  .footer__left {
    h4 {
      color: #062b5a;
      font-size: 1.5em;
    }

    img {
      height: 40px;
    }
  }

  .footer__right {

  }

}
