#contact-form {
  padding: 50px;
  background-image: url("../images/PDA_Home_Header.png");
  background-size: cover;

  h5 {
    font-size: 40px;
  }

  p {
    color: #fff;
    font-size: 20px;
  }

  input {
    width: 80%;
  }

  button {
    border-radius: 1rem;
  }

}
